import React, { useEffect, useState } from 'react';
import { getUsers } from '../../api/user';

export default function UserTable() {
  const [searchTerm, setSearchTerm] = useState('');
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const users = await getUsers(searchTerm);
        console.log(users.data, ' _________users');
        setUserData(users.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    console.log(searchTerm);
  };

  if (loading) {
    return <div>Yuklanmoqda...</div>;
  }

  if (error) {
    return <div>Tizim xatosi</div>;
  }

  return (
    <div className="flex flex-col items-center m-5">
      <div className="relative mt-4">
        <label htmlFor="table-search" className="sr-only">
          Search
        </label>
        <input
          type="text"
          className="px-4 py-2 w-64 border border-gray-300 rounded-lg placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          placeholder="Qidiruv"
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
      <div className="overflow-x-auto w-full mt-4">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Ism
              </th>
              <th scope="col" className="px-6 py-3">
                Maktab
              </th>
              <th scope="col" className="px-6 py-3">
                Qadam
              </th>
              <th scope="col" className="px-6 py-3">
                Statistika
              </th>
            </tr>
          </thead>
          <tbody>
            {userData.map((student) => (
              <tr
                key={student.id}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {student.full_name}
                </td>
                <td className="px-6 py-4">{student.school}</td>
                <td className="px-6 py-4">
                  {student.ai_step || student.web_step || student.mobile_step || ''}
                </td>
                <td className="px-6 py-4">
                  {((student.ai_step || student.web_step || student.mobile_step)) / 10}%
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}