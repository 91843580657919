import React from 'react';
import PricingCard from '../../ui/pricingCard';

export default function Pricing() {
  const advantages_pro = ['Dasturlash', 'Ingliz tili', 'Shaxsiy rivojlanish', 'Matematika']
  const advantages_vip = ['Dasturlash', 'Ingliz tili', 'Shaxsiy rivojlanish', 'Matematika', 'Haftada 6 kun']

  return (
    <div id='pricing' className='flex flex-col md:flex-row justify-evenly mt-5'>
        <PricingCard plan={'Pro'} description={'Haftada 2 kun uchun'} price={`300.000`} advantages={advantages_pro} /><br />
        <PricingCard plan={'VIP'} description={'Haftada 6 kun uchun'} price={`1.000.000`} advantages={advantages_vip} vip={true} />
    </div>
  );
}