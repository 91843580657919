import React from 'react'
import UserTable from '../components/userTable/userTable'

export default function Student() {
  return (
    <div>
      <UserTable />
    </div>
  )
}
