import React from 'react'
import Nav from './components/nav/nav'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/home'
import Student from './pages/student'
import './App.css'
export default function App() {
  return (
    <div className='App'>
      <Nav />

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/students' element={<Student />} />
      </Routes>
    </div>
  )
}
