import React, { useEffect, useRef } from 'react';
import LogoX from '../../assets/images/LogoX.svg';
import '../../styles/header/header.css';

const Header = () => {
  const logoRef = useRef(null);

  useEffect(() => {
    const logoElement = logoRef.current;

    const flipAnimation = () => {
      logoElement.classList.add('flip-animation');
    };

    const animationEndHandler = () => {
      logoElement.classList.remove('flip-animation');
      logoElement.addEventListener('animationend', animationEndHandler);
    };

    logoElement.addEventListener('animationend', animationEndHandler);

    flipAnimation();

    return () => {
      logoElement.removeEventListener('animationend', animationEndHandler);
    };
  }, []);

  return (
    <div className="sm:flex justify-around items-center">
      <div className="sm:w-1/2 p-10">
        <div className="image object-center text-center">
          <img
            src={LogoX}
            width={350}
            alt="LogoX"
            className="mx-auto logo animate-spin-slow"
            ref={logoRef}
          />
        </div>
      </div>
      <div className="sm:w-1/2 p-5">
      <figure class="max-w-screen-md mx-auto text-center">
          <svg class="w-10 h-10 mx-auto mb-3 text-gray-400 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 14">
              <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z"/>
          </svg>
          <blockquote>
              <p class="text-2xl italic font-medium text-gray-900 dark:text-white">
                "teamX akademiyasining asosiy maqsadi shuki - Oʻzbekistondagi eng katta ilm ulashishni yaxshi koʻradigan dasturchilar hamjamiyatini yaratish!"
                </p>
          </blockquote>
      </figure>
      </div>
    </div>
  );
};

export default Header;
