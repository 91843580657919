import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import NavLogo from '../../assets/images/navLogo.svg';

export default function Nav() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="border-gray-200  bg-purple-800 dark:bg-gray-800 dark:border-gray-700">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={NavLogo} className="h-14" alt="Flowbite Logo" />
        </Link>
        <button
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden focus:outline-none focus:ring-2  dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-solid-bg"
          aria-expanded={isMenuOpen ? 'true' : 'false'}
          onClick={toggleMenu}
        >
          <span className="sr-only">Open main menu</span>
          <svg className="text-white w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div
          className={`${
            isMenuOpen ? 'block' : 'hidden'
          } w-full md:block md:w-auto`}
          id="navbar-solid-bg"
        >
          <ul className="flex flex-col font-medium mt-4 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-transparent dark:bg-gray-800 md:dark:bg-transparent dark:border-gray-700">
            <li className='btn md:bg-yellow-customyellow pt-2 pb-2 pl-4 pr-4 rounded-md'>
            <Link
              to="/students"
              className={`block py-2 px-3 md:p-0 text-yellow-customyellow md:text-purple-800 rounded md:bg-transparent ${
                window.location.pathname === '/students' ? 'text-blue-700' : ''
              }`}
              aria-current="page"
            >
                O'quvchilar
              </Link>
            </li>
            <li className='btn md:bg-yellow-customyellow pt-2 pb-2 pl-4 pr-4 rounded-md'>
              <a
                href="/#pricing"
                className="block py-2 px-3 md:p-0 text-yellow-customyellow md:text-purple-800 0 rounded md:bg-transparent md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent"
                aria-current="page"
              >
                Narxlar
              </a>
            </li>
            <li className='btn text-yellow-customyellow md:bg-yellow-customyellow pt-2 pb-2 pl-4 pr-4 rounded-md'>
              <a
                href="#"
                className="block py-2 px-3 md:p-0 md:text-purple-800 rounded md:hover:bg-transparent md:border-0  dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                onClick={() =>
                  Swal.fire({
                    title: 'Adminstrator',
                    text: '+998 90 110 01 13',
                    icon: 'info',
                    confirmButtonText: 'OK',
                  })
                }
              >
                Adminstrator
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}