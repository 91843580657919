import React from 'react'
import Header from '../components/header/header'
import '../styles/pages/home.css'
import Service from '../components/service/service'
import Pricing from '../components/pricing/pricing'
import Footer from '../components/footer/footer'


export default function Home() {
  return (
    <>
        <div className='gradient'>
            <div className='p-5'>
                <Header />
            </div>
        </div>
        <div className=''>
            <Service />
        </div>
        <div>
          <Pricing />
        </div>
        <div>
          <Footer />
        </div>
    </>
    
  )
}
