import React from 'react';

export default function PricingCard({ plan, description, price, advantages, vip = false }) {
  return (
    <div className='flex flex-col items-center bg-slate-100 p-8 rounded-lg shadow-lg w-full md:w-[30%] md:mx-2'>
      <div>
        <div className="flex gap-4 justify-center">
          <p className="font-extrabold text-3xl mb-2">{plan}</p>
        </div>
        <p className="opacity-60 text-center">{description}</p>
        <div className="flex gap-4 justify-center">
          <div className="flex flex-col items-center my-8">
            <p className="font-extrabold text-4xl">{price} UZS</p>
            <p className="text-sm opacity-60">/oy</p>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-1">
        {advantages.map((advantage, index) => (
          <p key={index} className="flex items-center text-sm">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="w-4 h-4 mr-2">
              <path fillRule="evenodd" d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z" clipRule="evenodd"></path>
            </svg>
            <b>{advantage}</b>
          </p>
        ))}

        <div className="flex justify-center mt-8">
          <a href='https://t.me/PePe_Khabeer' target='_blank' className="px-4 py-2 border-violet-400 border-4 hover:bg-violet-100 rounded-xl">
            Boshlash
          </a>
        </div>
      </div>
    </div>
  );
}