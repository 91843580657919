import React from 'react';
import { FiPhone, FiMapPin } from 'react-icons/fi';
import { FaTelegram } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="mt-10 bg-gray-900 text-white py-4">
      <div className="container flex flex-col lg:flex-row items-center justify-around mx-auto">
        <div className="flex items-center mb-4 lg:mb-0">
          <FiPhone className="h-6 w-6 mr-2 text-white" />
          <span className="text-sm">+998 90 110 01 13</span>
        </div>
        <div className="flex items-center mb-4 lg:mb-0">
          <FiMapPin className="h-6 w-6 mr-2 text-white" />
          <span className="text-sm">Buxoro</span>
        </div>
        <div className="flex items-center">
          <FaTelegram className="h-6 w-6 mr-2 text-white" />
          <a href='https://t.me/PePe_Khabeer' target='_blank' className="text-sm">Telegram</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;